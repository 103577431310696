import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import List from 'components/List';
import { IdFilter, SearchFilter } from 'components/Filter';
import IdField from 'components/IdField';

const PermissionGroupsFilters = [
  <SearchFilter alwaysOn />,
  <IdFilter />,
  <EnumSelectInput alwaysOn source="account_type" />
];

const PermissionGroupsList = () => (
  <List exporter={false} filters={PermissionGroupsFilters}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField label="Account Type" source="account_type" />
      <TextField label="Display Name" source="display_name" />
    </Datagrid>
  </List>
);

PermissionGroupsList.query = gql`
  query GET_LIST($input: PermissionGroupQueryInput) {
    permissionGroups(input: $input) {
      id
      account_type
      display_name
      role
    }
    _permissionGroupsMeta(input: $input) {
      count
    }
  }
`;

export default PermissionGroupsList;
